<template>
  <div>
    <button @click="handleButtonClickMaia">
      Add Text and Download PDF (Maia template)
    </button>
    </br>
    <button @click="handleButtonClickBagsOfLuxury">
      Add Text and Download PDF (Bags of Luxury template)
    </button>
  </div>
</template>


<script>
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import axios from 'axios';

export default {
  name: 'PdfModifier',

  methods: {
    async modifyPdf(url) {

      // Fetch the PDF using axios.
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      const existingPdfBytes = response.data;

      // Load the PDF using pdf-lib.
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // Modify the PDF.
      const firstPage = pdfDoc.getPages()[0];
      const { height } = firstPage.getSize();

      firstPage.drawText('Name', {
        x: 61,
        y: 672,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('Address Line 1', {
        x: 61,
        y: 655,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('Address Line 2', {
        x: 61,
        y: 645,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('City', {
        x: 61,
        y: 635,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('Postcode', {
        x: 61,
        y: 625,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });



      firstPage.drawText('TEST123', {
        x: 450,
        y: 686,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('1st Feb 2024', {
        x: 419,
        y: 662,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('Qty', {
        x: 91,
        y: 526,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('Product Name', {
        x: 136,
        y: 526,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      firstPage.drawText('£20.00', {
        x: 485,
        y: 526,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });

      // Total
      firstPage.drawText('£20.00', {
        x: 485,
        y: 280,
        size: 8,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),

      });



      // Save the modified PDF to a variable (`pdfBytes`).
      const pdfBytes = await pdfDoc.save();

      // Now, you can use `pdfBytes` for any additional operations (e.g. downloading the modified PDF).
      return pdfBytes;
    },
    async handleButtonClick(pdfUrl) {
      try {
        const pdfBytes = await this.modifyPdf(pdfUrl);
        this.downloadPdf(pdfBytes);
      } catch (error) {
        console.error('Error modifying PDF:', error);
      }
    },
    downloadPdf(pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'modified_pdf.pdf';
      link.click();
    },
    handleButtonClickMaia() {
      this.handleButtonClick('Invoice_template_maia.pdf')
    },
    handleButtonClickBagsOfLuxury() {
      this.handleButtonClick('Invoice_template_luxury.pdf')
    },

  },
};
</script>
